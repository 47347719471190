<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #333;
  font-size: 14px;
}
body{
  background-color: #f5f9ff;
}
</style>
