<template>
  <div id="home" class="home">
    <div class="first-look">
      <div class="header">
        <div class="header-inner">
          <div class="logo">
            <img src="../assets/logo.png" width="30" />
            <div class="logo-text">派谕信息科技</div>
          </div>
          <div class="menu-wrapper">
            <div class="menu" :class="{ active: currentMenu === 'home' }" @click="onMenuClick('home')">首页</div>
            <div class="menu" :class="{ active: currentMenu === 'service' }" @click="onMenuClick('service')">服务内容</div>
            <div class="menu" :class="{ active: currentMenu === 'products' }" @click="onMenuClick('products')">产品介绍</div>
            <div class="menu" :class="{ active: currentMenu === 'contactus' }" @click="onMenuClick('contactus')">联系我们</div>
          </div>
        </div>
      </div>
      <div class="title-class" id="home" style="width: 100%; height: 100%">
        <el-carousel trigger="click" height="500px" :interval="5000" :pause-on-hover="true" style="width: 100%">
          <el-carousel-item>
            <div class="carousel-01" style="display: flex; align-items: center">
              <div class="text-black">
                <div class="main-text">软件系统定制开发</div>
                <div class="secondary-text">○ 根据企业实际需求和痛点，制定可行性方案</div>
                <div class="secondary-text">○ 提供1V1免费咨询，为企业发展提供一站式落地方案</div>
                <div class="secondary-text">○ 高标准的团队服务，追求细节和用户体验</div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-03" style="display: flex; align-items: center">
              <div class="text">
                <div class="main-text">企业级软件解决方案</div>
                <div class="secondary-text">○ 深入了解您的业务流程，提供量身定做的软件解决方案</div>
                <div class="secondary-text">○ 专业团队深度协作，确保解决方案的高效执行和交付</div>
                <div class="secondary-text">○ 专注于创新，采用最新技术打造先进的企业应用</div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-02" style="display: flex; align-items: center">
              <div class="text">
                <div class="main-text">透明的定价和项目管理</div>
                <div class="secondary-text">○ 项目全过程透明管理，保证每一步都符合预期</div>
                <div class="secondary-text">○ 公开透明的定价，无隐藏费用，确保每一分投资都物有所值</div>
                <div class="secondary-text">○ 注重长期合作，提供持续的技术支持和服务</div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="z-title title-class" id="service">我们为企业提供软件定制服务</div>
    <div class="z-sub-title">提供量身定制软件开发服务，采取专业的定制开发流程来满足各大企业软件产品的不同需求。</div>
    <div id="service" class="services">
      <div class="service" v-for="item in servicesList" :key="item">
        <div class="color-mask" :style="`background-color:${item.maskColor}`"></div>
        <div class="service-bg"><img :src="item.bg" /></div>
        <div class="container">
          <div class="icon"><img :src="item.icon" width="30" /></div>
          <div class="title">{{ item.title }}</div>
          <div class="divider">——</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <!-- 产品 -->
    <div id="products" class="products">
      <div class="z-title title-class" id="products">产品</div>
      <div class="z-divider">
        <div class="line"></div>
        <img src="@/assets/zuanshi.png" width="20" />
        <div class="line"></div>
      </div>
      <!-- 派谕认证中心系统 -->
      <div class="product">
        <div class="p-one">
          <div class="p-one-h">
            <div class="p-one-h-h">派谕认证中心系统</div>
            <div class="p-one-h-b">
              派谕认证中心系统，旨在保障企业员工身份认证和访问权限的安全性与合规性。通过自动化的工作流，能大幅降低人工运维成本，帮助企业安全高效地管理员工身份认证与权限变动的全流程。
            </div>
            <!-- <div class="p-one-h-m">查看更多</div> -->
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">单点登录</div>
            <div class="p-one-b-b">集成了微信、企业微信、支付宝、钉钉等应用，能帮助企业快速实现单点登录。用户只需登录一次，即可高效安全访问所有应用系统。</div>
            <div class="decoration">
              <div>减少重复登录</div>
              <div>提高团队产出</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>集成多种应用至一个工作平台进行单点登录，减少重复工作</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>直接复用单点登录能力至多个客户的项目中，减少重复开发的人力与时间成本</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>将多套关联账户绑定至用户唯一的手机号/账号，统一应用矩阵的账号体系，减少重复登录</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">权限管理</div>
            <div class="p-one-b-b">
              助力企业统一管理分散的在各应用中的权限系统，实现细致到某一行、某一按钮的授权策略，无需大量开发成本，即可编排自动化权限治理流程。
            </div>
            <div class="decoration">
              <div>精细化权限控制</div>
              <div>简化权限管理流程</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>集中管理各系统的资源权限，减低开发和运维工作量</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>自定义资源、操作和角色，满足个性化权限管理场景</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>将资源和操作赋予角色，轻松实现细粒度授权策略</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">分区隔离</div>
            <div class="p-one-b-b">
              从环境、项目、程序、应用几个方面多维度、精细化地对企业内部应用进行分区隔离，帮助开发人员在开发测试过程中的数据互不干扰。
            </div>
            <div class="decoration">
              <div>安全性增强</div>
              <div>数据隔离</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>开发人员在开发测试过程中的数据互不干扰</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>可以在非生产环境设置单独的角色权限进行开发测试</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>可以针对公司和项目设置独立的字典库，减少维护数据成本</div>
            </div>
          </div>
        </div>
      </div>
      <!-- CRM管理系统 -->
      <div class="product">
        <div class="p-one">
          <div class="p-one-h">
            <div class="p-one-h-h">CRM管理系统</div>
            <div class="p-one-h-b">
              CRM管理系统旨在通过改善客户关系和提升销售业绩，实现企业的长期发展和竞争优势。通过在客户管理、销售管理、市场营销、客户服务和业务分析等方面进行多维度管理，达到促进企业业务的增长和发展，提升整体业务运营水平，增强客户满意度和口碑的目的。
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">客户管理</div>
            <div class="p-one-b-b">统一客户资源，建立360°客户视图，实现对客户的信息化管理，确保企业随时掌握客户信息。</div>
            <div class="decoration">
              <div>客户信息集中管理</div>
              <div>客户开发和维护</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>随时随地记录客户信息，不断完善企业客户资源库</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>可通过客户分配规则，实现客户资源的自动化分配，提升效率</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>通过标签对客户实现分类分层管理，自定义标签内容，实现客户精细化管理，构建企业潜在客户画像</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">销售管理</div>
            <div class="p-one-b-b">根据有效的销售策略建立标准化销售流程，为销售团队制定统一的成功路线图，确保团队每个人都遵循正确的销售流程。</div>
            <div class="decoration">
              <div>销售机会跟踪</div>
              <div>销售预测和规划</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>根据商机不同阶段对销售发出跟进提醒，随时查看商机跟进情况，掌控销售流程全局，有效提升商机转化率</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>随时查看下级销售每日电话拨通量和外出拜访量</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>通过数据报表查看每个销售完成的签单情况和业绩额，自动计算任务完成指标和提成</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">数据分析</div>
            <div class="p-one-b-b">从线索、客户到销售收入等进行深入分析，通过直观的报表和图表，呈现企业的经营状况，快速发现问题和制定决策。</div>
            <div class="decoration">
              <div>销售漏斗图</div>
              <div>销售人员业绩图表</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>可自定义销售漏斗的每个阶段，帮您一眼看清每个阶段客户转化率，快速识别销售过程中的瓶颈</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>基于大数据和可视化技术, 为管理提供决策依据</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>含丰富的图表类型，包括柱状图、折线图、漏斗图、表格图、饼状图、面积图和热图等等，确保数据直观呈现。</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工单巡检管理系统 -->
      <div class="product">
        <div class="p-one">
          <div class="p-one-h">
            <div class="p-one-h-h">工单巡检管理系统</div>
            <div class="p-one-h-b">
              工单巡检管理是一种用于跟踪、管理和优化工单巡检过程的软件解决方案，旨在提高工作效率、优化资源利用、确保巡检质量、及时发现问题、提升客户满意度、支持决策制定和持续改进，从而实现企业的高效运转和持续发展。
            </div>
            <!-- <div class="p-one-h-m">查看更多</div> -->
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">工单管理</div>
            <div class="p-one-b-b">灵活智能的工单分配，可视化监管，有效提升跨部门协作效率和服务质量，帮助企业更快更高效解决客户问题。</div>
            <div class="decoration">
              <div>任务分配和跟踪</div>
              <div>提高工作效率</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>从工单创建、流转、到解决时效全程监控，工单流转中每个步骤、节点状态都清晰可见</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>实时消息提醒工单处理，并支持在小程序上进行工单的受理、处理、完结全流程，随时随地处理用户问题</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>一物对一码，设备信息/维修记录/设备档案，即刻调取</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">巡检管理</div>
            <div class="p-one-b-b">移动端数字化线上巡检；设备档案管理、巡检、保养，全流程覆盖；自动生成巡检路线，确保工作高效执行。</div>
            <div class="decoration">
              <div>标准化流程</div>
              <div>数据分析和优化</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>巡检方案条目清晰，现场定位+拍照记录，有效规避假巡检</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>根据巡检记录，系统自动更新设备状态或报修，派工、维修、验收，流程闭环，确保问题迅速解决</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>完善的统计报表和巡检报告，帮助企业分析总结问题原因，预先规避设备隐患</div>
            </div>
          </div>
          <div class="p-one-b">
            <div class="p-one-b-t">数据分析</div>
            <div class="p-one-b-b">维修效率、工单概览、库存等，设备状态一目了然；分析回顾全生命周期表现，完善流程和人员管理。</div>
            <div class="decoration">
              <div>优化决策过程</div>
              <div>发现趋势和模式</div>
            </div>
            <div class="dot-line"></div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>预测可能出现的故障或问题，帮助企业采取预防性措施，避免事故发生，提高系统的稳定性和可靠性</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>对工单巡检数据持续分析监控，持续改进工作流程和服务质量</div>
            </div>
            <div class="key-point">
              <img src="@/assets/check.png" width="15" height="15" />
              <div>分析工单处理的及时性、解决率、客户满意度等指标，评估团队的服务质量</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我们的优势 -->
    <div class="advantage-wrapper">
      <div class="z-title">我们的优势</div>
      <div class="z-divider">
        <div class="line"></div>
        <img src="@/assets/zuanshi.png" width="20" />
        <div class="line"></div>
      </div>
      <div class="ys-content">
        <div class="ys-item" v-for="item in advantagesList" :key="item">
          <div class="icon"><img src="@/assets/yiduxinxiang.png" width="35" /></div>
          <div class="text">
            <div class="tl">{{ item.title }}</div>
            <div class="ctx">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="about-us-wrapper title-class" id="contactus">
      <div class="z-title">关于我们</div>
      <div class="z-divider">
        <div class="line"></div>
        <img src="@/assets/zuanshi.png" width="20" />
        <div class="line"></div>
      </div>
      <div class="about-us-content">
        <div class="company-img">
          <img src="../assets/company.jpg" alt="" />
        </div>
        <div class="intro">
          <div class="intro-title">公司简介</div>
          <div class="intro-content">
            上海派谕信息科技有限公司专业从事软件开发，系统集成的企业，技术团队核心人员均拥有十年以上的软件和互联网行业从业经验，各个技术路线的负责人均是各细分技术领域的专家，
            公司主营业务包括物联网，企业信息系统网站，App，小程序以及公众号开发，以及专业技术人员驻场服务。
            我们秉承"客户至上，诚信为本"的理念，坚定不移地立足科技创新，持续不断为客户创造价值。
          </div>
          <div class="contact-us">联系我们</div>
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <el-icon style="margin-right: 5px"><Cellphone /></el-icon> 联系电话：15216723215
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <el-icon style="margin-right: 5px"><Location /></el-icon>地址：上海市中山北路1958号8楼840室
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <el-icon style="margin-right: 5px"><Location /></el-icon>邮箱：liujixin@paiyuit.com
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 10px">
            <img src="../assets/weixin2.png" width="15" style="margin-right: 5px" />微信号：ljx1136448782
          </div>
          <div style="display: flex; align-items: center"><img src="../assets/weixin2.png" width="15" style="margin-right: 5px" />微信二维码：</div>
          <div style="font-weight: bold; margin-top: 8px">
            <img src="../assets/微信二维码.png" width="120" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div>Copyright © 2020-2021 派谕信息科技有限公司</div>
      <a href="https://beian.miit.gov.cn/" style="color: #fff; font-size: 13px; text-decoration: none; margin-top: 5px"> 沪ICP备2021006166号-1 </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, onActivated, watch, computed, toRaw, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

const windowHeight = ref(0);
const servicesList = ref([
  {
    title: "驻场技术服务",
    content:
      "提供规范且专业的开发人员驻场开发技术服务，可满足企业灵活的用工需求，让企业能够聚焦核心主营业务，便捷地获取IT领域的先进技术，提高人力资源的效率，降低成本。",
    icon: require("../assets/service-icon-1.png"),
    bg: require("../assets/bg1.jpg"),
    maskColor: "#244861",
  },
  {
    title: "软件系统开发",
    content:
      "提供企业信息化解决方案定制开发服务，包括电商系统、物联网系统、ERP系统、CRM系统、OA系统等，同时提供技术咨询与系统集成服务，帮助企业合理规划数据、架构、商业组件的使用，形成与企业当前发展相匹配的信息化体系。",
    icon: require("../assets/service-icon-1.png"),
    bg: require("../assets/web.png"),
    maskColor: "#2b5464",
  },
  {
    title: "移动端APP开发",
    content:
      "提供基于iOS、Android系统的移动APP应用设计与开发。注重流畅、直观和愉悦的应用体验，致力于为企业打造高品质、创新性和功能丰富的移动应用程序，以满足企业独特的业务需求。",
    icon: require("../assets/service-icon-1.png"),
    bg: require("../assets/app.png"),
    maskColor: "#3c4d6b",
  },
  {
    title: "微信小程序开发",
    content:
      "致力于为客户打造具有创新性和功能性的微信小程序。根据客户的需求和品牌形象，为企业提供量身定制的解决方案，确保小程序与客户的业务目标完美匹配，帮助企业扩大品牌影响力，增加销售渠道，提升用户体验。",
    icon: require("../assets/service-icon-1.png"),
    bg: require("../assets/miniprogram.png"),
    maskColor: "#435460",
  },
]);

const advantagesList = ref([
  {
    title: "定制开发经验丰富",
    content: "公司始终致力于互联网领域发展，积累多年项目开发的经验，可以为客户提供成熟高效的软件服务",
    icon: require("../assets/service-icon-1.png"),
  },
  {
    title: "技术团队可靠",
    content: "我们拥有互联网全领域技术团队，专注解决应用开发中的各类难题，确保为客户提供优质满意的服务",
    icon: require("../assets/service-icon-1.png"),
  },
  {
    title: "发开周期短",
    content: "项目多节点设定，快速实现项目的每一个里程碑，节约开发时间，保障项目品质",
    icon: require("../assets/service-icon-1.png"),
  },
  {
    title: "售后服务完善",
    content: "我们会专门为您派遣专业的技术人员来教您项目后期的使用与操作，达到客户满意为止",
    icon: require("../assets/service-icon-1.png"),
  },
]);

// function scrollToTop(duration) {
//   const start = window.scrollY;
//   const end = 0;
//   const distance = end - start;
//   const startTime = performance.now();

//   function scrollStep() {
//     const currentTime = performance.now();
//     const elapsedTime = currentTime - startTime;
//     const progress = Math.min(elapsedTime / duration, 1);
//     window.scrollTo(0, start + distance * progress);
//     if (progress < 1) {
//       requestAnimationFrame(scrollStep);
//     }
//   }

//   requestAnimationFrame(scrollStep);
// }

const currentMenu = ref("home");

const onMenuClick = id => {
  currentMenu.value = id;

  const targetEl = document.querySelector(`#${id}`);
  // el.scrollIntoView({ behavior: "smooth" });

  let scrollTop = targetEl.offsetTop - 80;
  window.scroll({ top: scrollTop, behavior: "smooth" });

  // scrollToTop(scrollTop); // 1000ms = 1s
};

const onResize = () => {
  windowHeight.value = window.innerHeight;
};

onMounted(() => {
  const body = document.querySelector("body");
  windowHeight.value = window.innerHeight;
  window.addEventListener("resize", onResize);

  console.log(windowHeight.value);

  const sections = document.querySelectorAll(".title-class");

  // 添加scroll事件监听器
  window.addEventListener("scroll", () => {
    console.log(window.scrollY);
    let current = "";
    // 通过滚动位置确定当前活动的内容区块
    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      // if (scrollY >= sectionTop - sectionHeight / 3) {
      if (scrollY >= sectionTop - 300) {
        currentMenu.value = section.getAttribute("id");
      }
    });
  });
});
</script>

<style lang="scss" scoped>
$header-height: 55px;

.home {
  width: 100%;
  .first-look {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: $header-height;
    width: 100%;
    .header {
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 30px;
      height: $header-height;
      width: 100%;
      background-color: #fff;
      z-index: 1000;
      box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
      .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1280px;
        .logo {
          // padding: 10px 30px;
          display: flex;
          align-items: center;
          .logo-text {
            margin-left: 10px;
            margin-bottom: 1px;
            font-size: 19px;
            color: #222;
            font-weight: 500;
          }
        }
        .menu-wrapper {
          display: flex;
          .menu {
            padding: 10px;
            color: #222;
            cursor: pointer;
            font-size: 14px;
            &.active {
              color: #2b65a7;
              font-weight: 500;
            }
            &:hover {
              color: #2b65a7;
              font-weight: 500;
            }
          }
        }
      }
    }
    .carousel-03 {
      height: 100%;
      background-image: url("../assets/banner2.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .carousel-01 {
      // display: flex;
      // justify-content: flex-end;
      height: 100%;
      background-image: url("../assets/banner1.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .text-black {
        padding-left: 10%;
        font-size: 15px;
        // text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5); /* 设置阴影效果 */
        font-weight: bold;
        .main-text {
          margin-bottom: 20px;
          color: #1f1f1f;
          font-size: 44px;
          text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.5); /* 设置阴影效果 */
        }
        .secondary-text {
          margin-top: 10px;
          color: #1f1f1f;
        }
      }
    }
    .carousel-02 {
      height: 100%;
      background-image: url("../assets/banner3.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .text {
      padding-left: 10%;
      // text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* 设置阴影效果 */
      font-size: 15px;
      font-weight: bold;
      .main-text {
        margin-bottom: 20px;
        font-size: 44px;
        color: #f2f2f2;
        // text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); /* 设置阴影效果 */
      }
      .secondary-text {
        margin-top: 10px;
        color: #f2f2f2;
      }
    }
  }
  .services {
    display: flex;
    margin: 0 auto;
    .service {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 380px;
      .color-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        opacity: 0.9;
      }
      .service-bg {
        width: 100%;
        height: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .container {
        position: absolute;
        top: 0;
        left: 0;
        padding: 40px;
        width: 100%;
        height: 100%;
        z-index: 11;
        .icon {
          margin-bottom: 30px;
          text-align: center;
        }
        .title {
          margin-bottom: 30px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #f0f0f0;
        }
        .divider {
          margin-bottom: 30px;
          text-align: center;
          color: #f0f0f0;
        }
        .content {
          color: #f0f0f0;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
        }
      }
    }
  }
  .z-title {
    margin: 0 auto;
    margin-bottom: 10px;
    margin-top: 60px;
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    color: #212736;
  }
  .z-sub-title {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 70px;
    text-align: center;
    color: #333333;
    font-size: 15px;
  }
  .z-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    .line {
      margin: 0 10px;
      width: 80px;
      height: 1px;
      background-color: #d4d4d4;
    }
  }
  .advantage-wrapper {
    margin: 70px 0;
    .ys-content {
      display: flex;
      margin: 30px auto;
      width: 1280px;
      .ys-item {
        display: flex;
        padding: 20px;
        .icon {
        }
        .text {
          margin-left: 8px;
          .tl {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #212736;
          }
          .ctx {
            color: #5e6078;
          }
        }
      }
    }
  }
  .products {
    margin-top: 70px;
    margin-bottom: 70px;
    .product {
      margin-top: 50px;
      .p-one {
        margin: 0 auto;
        display: flex;
        width: 1283px;
        // height: 370px;
        .p-one-h {
          padding: 60px 40px;
          width: 25%;
          background-color: #3f4b6b;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          .p-one-h-h {
            font-size: 22px;
            color: #fff;
          }
          .p-one-h-b {
            margin-top: 25px;
            color: #fff;
            font-size: 13px;
            line-height: 26px;
          }
          .p-one-h-m {
            margin-top: 30px;
            padding: 5px 15px;
            border: 1px solid #fff;
            width: fit-content;
            border-radius: 5px;
            color: #fff;
            font-size: 13px;
          }
        }
        .p-one-b {
          margin-left: -1px;
          padding: 35px 30px;
          width: 25%;
          border: 1px solid #f0f0f0;
          background-color: #fff;
          .p-one-b-t {
            margin-bottom: 20px;
            font-size: 18px;
            color: #212736;
          }
          .p-one-b-b {
            margin-bottom: 13px;
            font-size: 13px;
            color: #5e6078;
            line-height: 19px;
          }
          .decoration {
            display: flex;
            margin-bottom: 30px;
            div {
              margin-right: 5px;
              padding: 1px 8px;
              border: 1px solid #fdb77a;
              font-size: 13px;
              border-radius: 4px;
              color: #fe8010;
            }
          }
          .dot-line {
            margin-bottom: 30px;
            border-top: 1px dashed #d9d9d9;
          }
          .key-point {
            margin-top: 3px;
            display: flex;
            div {
              margin-left: 10px;
              margin-bottom: 4px;
              font-size: 13px;
              color: #5d5f77;
            }
          }
        }
      }
    }
  }
  .about-us-wrapper {
    margin: 70px 0;
    .about-us-content {
      display: flex;
      // padding: 30px;
      overflow: hidden;
      margin: 30px auto;
      width: 1280px;
      background-color: #fff;
      border-radius: 5px;
      .company-img {
        width: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .intro {
        padding: 40px;
        width: 50%;
        .intro-title {
          margin-bottom: 15px;
          font-size: 20px;
          color: #212736;
        }
        .intro-content {
          line-height: 24px;
          color: #5d5f77;
        }
        .contact-us {
          margin-top: 20px;
          margin-bottom: 15px;
          font-size: 20px;
          color: #212736;
        }
        .contact-us-content {
          // margin-bottom: 10px;
          // display: flex;
          // align-items: center;

          img {
            margin-right: 6px;
          }
          span {
            color: #5d5f77;
          }
        }
      }
    }
  }
  .footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4b556d;
    height: 70px;
    div {
      color: #f2f2f2;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
</style>
